html,
body {
    height: 100%;
    margin: 0;
    min-height: 100%;
}

body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#root {
    display: flex;
    flex-flow: column;
    height: 100%;
    min-height: 100%;
    overflow: hidden;
    padding-bottom: 0;
    user-select: none;
}

main {
    display: flex;
    flex: 1 1 auto;
    justify-content: center;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
}

main::-webkit-scrollbar {
    width: 9px;
}

main::-webkit-scrollbar-track {
    background: #7baaed33;
    border-radius: 50px;
    border: none;
}

main::-webkit-scrollbar-thumb {
    background: #7baaed;
    border-radius: 50px;
}

footer {
    align-items: center;
    display: flex;
    flex: 0 1 80px;
    justify-content: center;
    padding-bottom: 12px;
}

.hidden {
    display: none!important;
}